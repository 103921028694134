import { DEFAULT_CACHE_KEY, generateCacheTTL } from '../../../utils/functions/cache';
import {
  GET_INVESTMENTS_API_REQUEST,
  GET_INVESTMENTS_API_SUCCESS,
  GET_INVESTMENTS_API_ERROR,
  GET_INVESTMENT_CALCULATIONS_API_REQUEST,
  GET_INVESTMENT_CALCULATIONS_API_SUCCESS,
  GET_INVESTMENT_CALCULATIONS_API_ERROR,
  ADD_INVESTMENT_ACCOUNT_SUCCESS,
  CHANGE_BALANCE_DATE_SUCCESS,
  UPDATE_SINGLE_ACCOUNT,
} from '../../../constants/portfolio/investmentActionNames';
import { RESET_INSURANCE } from '../../../constants/portfolio/insuranceActionNames';
import {
  SELECT_CLIENTS_SUCCESS,
  SIGNIN_API_SUCCESS,
} from '../../../constants/authActionNames';

import {
  stringToDate,
  isoStringToDate,
  uuidv4,
  dateToString,
  generateSearchWords,
  getKeys,
  getAllKeys,
  uniq,
  addItemOnce,
} from '../../../utils/functions';
import { loadInvestmentCount, persistKey, investmentLob } from '../../../utils/variables';
// import lobTypes from '../../../utils/variables/lobTypes.json';
import payloadData from './payloadData';

const slugify = require('slugify');

let searchKeys = [];
export const createCalculation = object => {
  // FIXME: set the holdingValDate properly
  return {
    // securityCode: object?.securityCode,
    ...object,
    quantity: object?.quantity,
    price: object?.price,
    priceDate: object?.priceDate,
    multiplier: object?.multiplier,
    valueHoldingCurrency: !Number.isNaN(parseFloat(object?.valueSecurityCurrency))
      ? parseFloat(object?.valueSecurityCurrency)
      : 0,
    preferredCurrencyFxRate: object?.preferredCurrencyFxRate,
    // reportingCurrencyFxRate: object?.reportingCurrencyFxRate,
    valuePreferredCurrency: !Number.isNaN(parseFloat(object?.valuePreferredCurrency))
      ? parseFloat(object?.valuePreferredCurrency)
      : 0,
    holdingValDate: dateToString(isoStringToDate(object?.priceDate || object?.valueDate)),
    valueDate: dateToString(isoStringToDate(object?.valueDate)),
    filterDate: dateToString(isoStringToDate(object?.valueDate)),
    accruedInterestSecCurrency: !Number.isNaN(
      parseFloat(object?.accruedInterestSecurityCurrency),
    )
      ? parseFloat(object?.accruedInterestSecurityCurrency)
      : 0,
    accruedInterestPrefCurrency: !Number.isNaN(
      parseFloat(object?.accruedInterestPreferredCurrency),
    )
      ? parseFloat(object?.accruedInterestPreferredCurrency)
      : 0,
    unrealizedPLSecCurrency: object?.unrealizedPL,
    averagePrice: object?.averagePrice,
  };
  // });
};

const createTransactions = payloadArray => {
  if (!Array.isArray(payloadArray)) return null;
  return payloadArray.map((object, index) => {
    const position = index;
    return {
      ...object,
      index: position,
      no: position + 1,
      id: index,
      lotId: object?.lotId,
      type: object?.type,
      date: dateToString(isoStringToDate(object?.date)),
      quantity: object?.qty,
      price: object?.price,
      priceDate: object?.priceDate,
      fees: object?.fees,
      interest: 'N/A',
      value: object?.trxValue,
      reversed: object?.reversed,
      name: object?.security,
      fxRate: object?.fxRate,
      tax: object?.tax,
      deltaDate: dateToString(isoStringToDate(object?.deltaDate)),
      income: object?.income,
      mult: object?.mult,
    };
  });
};

const createHoldings = payloadArray => {
  if (!Array.isArray(payloadArray)) return null;
  const holdings = payloadArray.map((object, index) => {
    // // console.log('object?.valuePreferredCurrency : ', object?.valuePreferredCurrency);
    const quantity = 0; //! Number.isNaN(object?.qty) ? parseFloat(object?.qty) : 0;
    // // console.log('quantity: ', quantity);
    // if (!quantity) return null;

    return {
      ...object,
      id: index,
      securityCode: object?.secCode,
      name: object?.security,
      currency: object?.currency,
      assetType: object?.secType,
      holdingValDate: dateToString(isoStringToDate(object?.holdingValDate)),
      quantity, //! Number.isNaN(object?.qty) ? parseFloat(object?.qty) : object?.qty,
      filterDate: dateToString(isoStringToDate(object?.holdingValDate)),
      totalCost: object?.totalCost,
      sector: object?.sector,
      deltaDate: dateToString(isoStringToDate(object?.deltaDate)),
      valuePreferredCurrency: 'N/A',
      valueHoldingCurrency: 'N/A',
      percentOfClosingBalance: 'N/A',

      country: 'N/A',
      accruedInterestSecCurrency: 'N/A',
      accruedInterestPrefCurrency: 'N/A',
      unrealizedPLSecCurrency: 'N/A',
      averageUnitCost: 'N/A',

      multiplier: 'N/A',
      preferredCurrencyFxRate: 'N/A',
      reportingCurrencyFxRate: 'N/A',
      valueDate: 'N/A',
      price: 'N/A',
      priceDate: 'N/A',
      averagePrice: 'N/A',

      recentTransactions: createTransactions(object?.lots),
    };
  });

  const newHoldings = holdings.filter(el => {
    return el != null;
  });

  return newHoldings;
};

const createOwners = payloadArray => {
  if (!Array.isArray(payloadArray)) return null;
  return payloadArray.map((object, index) => {
    return {
      ...object,
      id: index,
      master: object?.master,
      displaySourceId: object?.displaySourceId,
      name: object?.name,
      primaryPhone: object?.primaryPhone,
      transact: object?.transact,
      withdraw: object?.withdraw,
    };
  });
};

const getAccessType = accessConfig => {
  const accessTypeArray = [];
  const {
    hasDelegateReaderAccess,
    hasDelegateApproverAccess,
    hasDelegateRequesterAccess,
    hasDelegateOwnerAccess,
    isCorporateOwner,
    isPersonalOwner,
  } = accessConfig || {};

  if (isPersonalOwner) accessTypeArray.push('Personal Owner');
  else if (isCorporateOwner) accessTypeArray.push('Corporate Owner');

  if (hasDelegateOwnerAccess) accessTypeArray.push('Delegate Owner');
  else if (hasDelegateApproverAccess) accessTypeArray.push('Delegate Approver');
  else if (hasDelegateRequesterAccess) accessTypeArray.push('Delegate Requester');
  else if (hasDelegateReaderAccess) accessTypeArray.push('Delegate Reader');

  return accessTypeArray.join(', ');
};

// parseInt(startIndex + index, 10)
export const createAccountsPayload = (payloadArray, lobTypes) => {
  // // console.log('payloadArray: ', payloadArray);
  if (!payloadArray || !Array.isArray(payloadArray)) return null;
  searchKeys = [];
  return payloadArray.map((object, index) => {
    const position = index;
    const accountType = object?.originalType || object?.type;
    const lobObj = lobTypes.find(x => x.lob === investmentLob);
    const subType = lobObj?.subTypes.find(x => x.slug === accountType);

    const holdings = createHoldings(object?.holdings);

    const owners = createOwners(object?.owner);

    const accessType = getAccessType(object?.accessConfig);

    const slug =
      object?.title &&
      slugify(object?.title, {
        lower: true, // Convert to lowercase
        strict: true, // Remove special characters
      });
    const newTitle = `${subType?.title} - ${object.id}`;
    const account = {
      ...object,
      accessType,
      index: position,
      lob: investmentLob,
      filterLob: investmentLob,
      id: object.id ? object?.id : uuidv4(), // `${object?._id}_${uiid}`,
      accountId: object?.id,
      policyId: object?.id,
      accountIdNoSpace: object?.id?.replace(/ /g, ''),
      type: object?.type,
      accountType,
      accountTypeIcon: subType?.materialIcon,
      accountTypeTitle: subType?.title,
      color: lobObj?.color,
      icon: lobObj?.icon,
      planId: slug,
      slug,
      planType: accountType?.toLowerCase(),
      materialIcon: lobObj?.materialIcon,
      valueDate: 'N/A',
      issueDate: dateToString(isoStringToDate(object?.issueDate)),
      filterDate: dateToString(isoStringToDate(object?.issueDate)),
      filterCurrency: object?.currency,
      filterStatus: object?.status,
      closingBalance: 'N/A', // 545000, //
      reportingCurrency: object?.currency,
      status: object?.status,
      name: object?.title,
      phone: object?.phone,
      email: object?.email,
      fundAmount: object?.FundAmount,
      masterId: object?.masterId,
      owners,
      ownerFullName: `${owners[0]?.name}`,
      ownerFullNameNoSpace: `${owners[0]?.name}`?.replace(/ /g, ''),
      holdings,
      isJoint: object?.isJoint,
      isPayee: object?.isPayee,
      isTransact: object?.isTransact,
      jointName: object?.jointName,

      isHideRequest: object?.isJoint === true && object?.isTransact === false,
      gamAccount: object?.gamAccount,
      newTitle,
      // isJointTransact: object?.isJoint && object?.isTransact,

      // ...searchWords,
    };

    account.gamJointName = account?.isJoint && account?.jointName;
    account.gamOwnerName = !account?.isJoint && account?.ownerFullName;
    // const searchWords = generateSearchWords(object);
    // // console.log('searchWords: ', searchWords);
    // const newKeys = getKeys(searchWords);
    // searchKeys = [...searchKeys, ...newKeys];
    // searchKeys = uniq(searchKeys);
    // // console.log('searchKeys: ', searchKeys);

    // const reduceObj = reduceKeys(obj);

    const newKeys = getAllKeys(account);
    searchKeys = [...searchKeys, ...newKeys];
    searchKeys = uniq(searchKeys);

    // // console.log('searchKeys: ', searchKeys);

    return account;
  });
};

export const updateHoldingsWithCalculations = (
  payloadArray,
  accountCalculation,
  params,
) => {
  if (!Array.isArray(payloadArray)) return payloadArray;
  if (!Array.isArray(accountCalculation?.calculations)) return payloadArray;
  const accountId = accountCalculation?.accountId;
  // // console.log('updating accountId: ', accountId);
  const accounts = [...payloadArray];
  const accountIndex = accounts.findIndex(object => object.accountId === accountId);
  const account = accounts.find(object => object.accountId === accountId);
  const holdings = account?.holdings;
  // // console.log('holdings: ', holdings);
  const maxHoldingDate = params?.holdingDate || dateToString(new Date());

  if (!Array.isArray(holdings)) return payloadArray;
  const calculations = accountCalculation?.calculations;
  // // console.log('calculations: ', calculations);

  const newHoldings = holdings.map(holding => {
    return {
      ...holding,
      ...createCalculation(
        calculations.find(
          holdingCalculation =>
            holding?.securityCode === holdingCalculation?.securityCode,
        ),
      ),
    };
  });
  // // console.log('newHoldings: ', newHoldings);

  const filteredHoldings = newHoldings.filter(
    x =>
      x.holdingValDate &&
      x.holdingValDate <= maxHoldingDate &&
      x.quantity !== undefined &&
      x.quantity !== null &&
      // x.quantity > 0 &&
      x.securityCode,
  );

  // // console.log('filteredHoldings: ', filteredHoldings);

  // // console.log('newHoldings: ', newHoldings);
  // calculate closing balance and value date
  const closingBalance = filteredHoldings.reduce((acc, currentObject) => {
    const prefCurrency = currentObject?.valuePreferredCurrency;
    const accruedPrefCurrency = currentObject?.accruedInterestPrefCurrency;
    return acc + prefCurrency + accruedPrefCurrency;
  }, 0);
  // // console.log('closingBalance: ', closingBalance);
  // // console.log('newHoldings: ', filteredHoldings);
  // // console.log('filteredHoldings: ', filteredHoldings);
  const valueDate = filteredHoldings.reduce(
    (acc, currentObject) =>
      currentObject?.holdingValDate > acc ? currentObject?.holdingValDate : acc,
    filteredHoldings[0]?.holdingValDate,
  );
  // // console.log('valueDate: ', valueDate);
  // // console.log('closingBalance: ', closingBalance);

  const finalHoldings = filteredHoldings.map(x => {
    const prefCurrency = x?.valuePreferredCurrency;
    return {
      ...x,
      percentOfClosingBalance: parseFloat(
        (prefCurrency * 100) / parseFloat(closingBalance),
      ),
    };
  });

  const newAccount = {
    ...account,
    holdings: finalHoldings,
    closingBalance,
    valueDate,
    balanceDate: maxHoldingDate,
    isCalculated: true,
  };
  // // console.log('newAccount: ', newAccount);

  if (accountIndex !== -1) {
    accounts.splice(accountIndex, 1, newAccount);
  } else {
    accounts.push(newAccount);
  }

  return accounts;
};

export const getPayloadResponse = () => {
  // // console.log('params: ', params);
  return { payload: payloadData };
};

export const initialState = {
  response: null,
  accounts: [],
  accountId: null,
  searchKeys,
  initialAccounts: [],
  pendingStatuses: {},
  successStatuses: {},
  isPending: false,
  isError: false,
  isSuccess: false,
  error: null,
  investmentCalculationResponse: null,
  isInvestmentCalculationPending: false,
  isInvestmentCalculationError: false,
  isInvestmentCalculationSuccess: false,
  investmentCalculationError: null,
  balanceDate: dateToString(new Date()),
  [persistKey]: null,
  [DEFAULT_CACHE_KEY]: null,
  currentCacheKey: '',
  account: {},
};

function investmentReducer(state = initialState, action) {
  switch (action.type) {
    case GET_INVESTMENT_CALCULATIONS_API_REQUEST: {
      const params = action?.params;
      const accountId = params?.policyId;
      // const pendingStatuses = { ...state?.pendingStatuses, [accountId]: true };
      // const successStatuses = { ...state?.successStatuses, [accountId]: false };
      const pendingStatuses = {
        ...state?.pendingStatuses,
        [accountId]: true,
      };
      const successStatuses = {
        ...state?.successStatuses,
        [accountId]: false,
      };
      // // console.log('newPendingAccounts Request: ', newPendingAccounts);
      return {
        ...state,
        isInvestmentCalculationPending: true,
        isInvestmentCalculationError: false,
        isInvestmentCalculationSuccess: false,
        pendingStatuses,
        successStatuses,
      };
    }
    case CHANGE_BALANCE_DATE_SUCCESS: {
      // const pendingStatuses = { ...initialState?.pendingStatuses };
      // const successStatuses = { ...initialState?.successStatuses };
      const pendingStatuses = { ...initialState?.pendingStatuses };
      const successStatuses = { ...initialState?.successStatuses };
      return {
        ...state,
        balanceDate: action?.payload,
        pendingStatuses,
        successStatuses,
      };
    }

    case GET_INVESTMENT_CALCULATIONS_API_SUCCESS: {
      const params = action?.params;
      const accountId = params?.policyId;
      const cacheKey = action?.cacheKey;
      // const pendingStatuses = { ...state?.pendingStatuses, [accountId]: false };
      // const successStatuses = { ...state?.successStatuses, [accountId]: true };
      const pendingStatuses = {
        ...state?.pendingStatuses,
        [accountId]: false,
      };
      const successStatuses = {
        ...state?.successStatuses,
        [accountId]: true,
      };
      // // console.log('newPendingAccounts Success: ', newPendingAccounts);

      const response = action.payload;

      const accountCalculation = response?.payload;
      const accountIdKey = accountCalculation?.accountId;
      const accounts = updateHoldingsWithCalculations(
        state?.accounts,
        accountCalculation,
        params,
      );

      const nowTime = new Date().getTime();

      if (response) {
        response.payload = null;
      }

      return {
        ...state,
        isInvestmentCalculationPending: false,
        isInvestmentCalculationError: false,
        isInvestmentCalculationSuccess: true,
        investmentCalculationResponse: response,
        accountId: accountIdKey,
        accounts,
        pendingStatuses,
        successStatuses,
        balanceDate: params?.balanceDate,
        [DEFAULT_CACHE_KEY]: {
          ...(state[DEFAULT_CACHE_KEY] || {}),
          [cacheKey]: {
            time: generateCacheTTL(),
            payload: null,
          },
        },
        [persistKey]: nowTime,
      };
    }
    case GET_INVESTMENT_CALCULATIONS_API_ERROR: {
      const params = action?.params;
      const accountId = params?.policyId;
      // const pendingStatuses = { ...state?.pendingStatuses, [accountId]: false };
      // const successStatuses = { ...state?.successStatuses, [accountId]: false };
      const pendingStatuses = {
        ...state?.pendingStatuses,
        [accountId]: false,
      };
      const successStatuses = {
        ...state?.successStatuses,
        [accountId]: false,
      };
      // // console.log('newPendingAccounts Error: ', newPendingAccounts);

      return {
        ...state,
        isInvestmentCalculationPending: false,
        isInvestmentCalculationError: true,
        isInvestmentCalculationSuccess: false,
        // investmentCalculationError: action.payload,
        pendingStatuses,
        successStatuses,
      };
    }
    case RESET_INSURANCE: {
      const newPersistKey = state[persistKey];
      // console.log('newPersistKey: ', newPersistKey);

      return {
        ...initialState,
        [persistKey]: newPersistKey,
      };
    }

    case GET_INVESTMENTS_API_REQUEST:
      return {
        ...state,
        isPending: true,
        isError: false,
        isSuccess: false,
      };
    case GET_INVESTMENTS_API_SUCCESS: {
      const response = action?.payload;
      const payload = response?.payload;
      const params = action?.params;
      const startIndex = params?.startIndex || 0;
      const lobTypes = action?.lobTypes;
      const cacheKey = action?.cacheKey;
      // const payload = !!fullPayload && fullPayload.length > 0 ? fullPayload[0] : null;
      // // console.log('payload: ', payload);
      // const myAccounts = payload?.policies || [];
      const accounts = createAccountsPayload(payload, lobTypes) || [...state?.accounts];

      const nowTime = new Date().getTime();

      // response.payload = null;
      return {
        ...state,
        isPending: false,
        isError: false,
        isSuccess: true,
        response, // newPortfolio,
        initialAccounts: accounts,
        accounts,
        searchKeys,
        [persistKey]: nowTime,
        currentCacheKey: cacheKey,
        [DEFAULT_CACHE_KEY]: {
          ...state[DEFAULT_CACHE_KEY],
          [cacheKey]: {
            time: generateCacheTTL(),
            payload: null,
          },
        },
      };
    }
    case GET_INVESTMENTS_API_ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        isSuccess: false,
        error: action.payload,
        // pendingStatuses: {},
        // successStatuses: {},
      };

    case SELECT_CLIENTS_SUCCESS: {
      return {
        ...state,
        [persistKey]: null,
      };
    }

    case UPDATE_SINGLE_ACCOUNT: {
      return {
        ...state,
        account: action?.payload,
      };
    }

    case SIGNIN_API_SUCCESS: {
      return {
        ...state,
        [persistKey]: null,
        accounts: [],
        initialAccounts: [],
      };
    }

    default:
      return state;
  }
}
export default investmentReducer;
