import { dutchInusranceProductType } from '../../../utils/variables/index';

export const dutchMotorInfoText = {
  stpInvalid:
    'Thank you for your responses! We’ll review your request. Please complete your application, and we will contact you soon..',
  stpInvalidForInsuredAmount: {
    ANG:
      'You are insured for third party liability (liability amount 500,000) immediately after purchase.\n An extra evaluation is needed for a higher liability amount.',
    AWG:
      'You are insured for third party liability (liability amount 500,000) immediately after purchase.\n An extra evaluation is needed for a higher liability amount.',
    USD:
      'You are insured for third party liability (liability amount 280,000) immediately after purchase.\n An extra evaluation is needed for a higher liability amount.',
  },
  carPersonInspection:
    'You’re insured for Third-Party Liability (WA) immediately after purchase.\nA vehicle inspection is needed for Fire/Explosion & Theft. Check your email for further steps',
  carInspection:
    'You’re insured for Third-Party Liability (WA) immediately after purchase.\nA vehicle inspection is needed for Fire/Explosion & Theft. Check your email for further steps',
  personInspection:
    'You’re insured for Third-Party Liability (WA) immediately after purchase.\nAn extra evaluation is needed for Fire/Explosion & Theft. Check your email for further steps',
  blocked:
    'We’re sorry, based on your answers we are unable to complete your request online.\nFor a customized offer, click here for customer service .',
  legalCheck:
    'Thank you for your responses! We’ll review your request. Please complete your application, and we will contact you soon..',
};

export const dutchMotorCascoInfoText = {
  stpInvalid:
    'Thank you for your responses! We’ll review your request. Please complete your application, and we will contact you soon..',
  stpInvalidForInsuredAmount: {
    ANG:
      'You are insured for third party liability (liability amount 500,000) immediately after purchase.\n An extra evaluation is needed for a higher liability amount.',
    AWG:
      'You are insured for third party liability (liability amount 500,000) immediately after purchase.\n An extra evaluation is needed for a higher liability amount.',
    USD:
      'You are insured for third party liability (liability amount 280,000) immediately after purchase.\n An extra evaluation is needed for a higher liability amount.',
  },
  carPersonInspection:
    'You’re insured for Third-Party Liability (WA) immediately after purchase.\n A vehicle inspection is needed for Casco coverage. Check your email for further instructions.',
  carInspection:
    'You’re insured for Third-Party Liability (WA) immediately after purchase.\n A vehicle inspection is needed for Casco coverage. Check your email for further instructions.',
  personInspection:
    'You’re insured for Third-Party Liability (WA) immediately after purchase.\n An extra evaluation is needed for Casco coverage. Check your email for further steps.',
  blocked:
    'We’re sorry, based on your answers we are unable to complete your request online.\n For a customized offer, click here for customer service .',
  legalCheck:
    'Thank you for your responses! We’ll review your request. Please complete your application, and we will contact you soon..',
};

export const dutchInfotext = {
  stpInvalid:
    'Thank you for your responses! We’ll review your request. Please complete your application, and we will contact you soon..',
  // carPersonInspection:
  //   'Based on your answers we need to do a quick vehicle inspection. Please complete your application and\ncheck your email for further steps!',
  // carInspection:
  //   'Based on your answers we need to do a quick vehicle inspection. Please complete your application and\ncheck your email for further steps!',
  personInspection:
    'Thank you for your responses! We’ll review your request. Please complete your application, and we will contact you soon..',
  blocked:
    'Thank you for your responses! We’ll review your request. Please complete your application, and we will contact you soon..',
  legalCheck:
    'Thank you for your responses! We’ll review your request. Please complete your application, and we will contact you soon..',
};

export const breakDownTemplate = {
  [dutchInusranceProductType.WA]: [
    {
      type: 'base_wa',
      description: 'Third Party Liability coverage',
      heading: 'Liability coverage (Third Party Liability)',
    },
    {
      type: 'accident_insurance_costs',
      description: 'Personal Accident Coverage',
      hideWhenZero: true,
    },
    {
      type: 'uninsured_third_party_coverage_costs',
      description: 'Uninsured Motorist Coverage Cost',
      hideWhenZero: true,
    },
    {
      type: 'administration_costs',
      description: 'Administration costs',
    },
    {
      type: 'tax',
      isDividerHidden: true,
      description: 'Insurance tax',
    },
    {
      type: 'totalPremium',
      description: 'Total premium ',
      isHighlighted: true,
    },
  ],
  [dutchInusranceProductType.WA_PLUS]: [
    {
      type: 'base_wa',
      description: 'Third Party Liability coverage',
      heading: 'Liability coverage (Third Party Liability)',
    },
    {
      type: 'base_casco',
      description: ' Fire/Explosion & Theft',
      heading: 'Liability Plus coverage (Third Party Liability Plus)',
      hideWhenZero: true,
    },
    {
      type: 'accident_insurance_costs',
      description: 'Personal Accident Coverage',
      hideWhenZero: true,
    },
    {
      type: 'uninsured_third_party_coverage_costs',
      description: 'Uninsured Motorist Coverage Cost',
      hideWhenZero: true,
    },
    {
      type: 'administration_costs',
      description: 'Administration costs',
    },
    {
      type: 'tax',
      isDividerHidden: true,
      description: 'Insurance tax',
    },
    {
      type: 'totalPremium',
      description: 'Total premium ',
      isHighlighted: true,
    },
  ],
  [dutchInusranceProductType.CASCO]: [
    {
      type: 'base_wa',
      description: 'Third Party Liability coverage',
      heading: 'Liability coverage (Third Party Liability)',
    },
    {
      type: 'base_casco',
      description: 'Own damage coverage',
      heading: 'Casco TPL/WA (Own Damage)',
      hideWhenZero: true,
    },
    {
      type: 'accident_insurance_costs',
      description: 'Personal Accident Coverage',
    },
    {
      type: 'uninsured_third_party_coverage_costs',
      description: 'Uninsured Motorist Coverage Cost',
      hideWhenZero: true,
    },
    {
      type: 'administration_costs',
      description: 'Administration costs',
    },
    {
      type: 'tax',
      isDividerHidden: true,
      description: 'Insurance tax',
    },
    {
      type: 'totalPremium',
      description: 'Total premium ',
      isHighlighted: true,
    },
  ],
  [dutchInusranceProductType.CASCO_1]: [
    {
      type: 'base_wa',
      description: 'Third Party Liability coverage',
      heading: 'Liability coverage (Third Party Liability)',
    },
    {
      type: 'base_casco',
      description:
        'Own Damage (Casco) coverage with 1 year replacement value and depreciation ',
      heading: 'Casco TPL/WA (Own Damage)',
      hideWhenZero: true,
    },
    {
      type: 'accident_insurance_costs',
      description: 'Personal Accident Coverage',
    },
    {
      type: 'uninsured_third_party_coverage_costs',
      description: 'Uninsured Motorist Coverage Cost',
      hideWhenZero: true,
    },
    {
      type: 'administration_costs',
      description: 'Administration costs',
    },
    {
      type: 'tax',
      isDividerHidden: true,
      description: 'Insurance tax',
    },
    {
      type: 'totalPremium',
      description: 'Total premium ',
      isHighlighted: true,
    },
  ],
  [dutchInusranceProductType.CASCO_2]: [
    {
      type: 'base_wa',
      description: 'Third Party Liability coverage',
      heading: 'Liability coverage (Third Party Liability)',
    },
    {
      type: 'base_casco',
      description:
        'Own Damage (Casco) coverage with 2 year replacement value and depreciation ',
      heading: 'Casco TPL/WA (Own Damage)',
      hideWhenZero: true,
    },
    {
      type: 'accident_insurance_costs',
      description: 'Personal Accident Coverage',
    },
    {
      type: 'uninsured_third_party_coverage_costs',
      description: 'Uninsured Motorist Coverage Cost',
      hideWhenZero: true,
    },
    {
      type: 'administration_costs',
      description: 'Administration costs',
    },
    {
      type: 'tax',
      isDividerHidden: true,
      description: 'Insurance tax',
    },
    {
      type: 'totalPremium',
      description: 'Total premium ',
      isHighlighted: true,
    },
  ],
  [dutchInusranceProductType.CASCO_3]: [
    {
      type: 'base_wa',
      description: 'Third Party Liability coverage',
      heading: 'Liability coverage (Third Party Liability)',
    },
    {
      type: 'base_casco',
      description:
        'Own Damage (Casco) coverage with 3 year replacement value and depreciation ',
      heading: 'Casco TPL/WA (Own Damage)',
      hideWhenZero: true,
    },
    {
      type: 'accident_insurance_costs',
      description: 'Personal Accident Coverage',
    },
    {
      type: 'uninsured_third_party_coverage_costs',
      description: 'Uninsured Motorist Coverage Cost',
      hideWhenZero: true,
    },
    {
      type: 'administration_costs',
      description: 'Administration costs',
    },
    {
      type: 'tax',
      isDividerHidden: true,
      description: 'Insurance tax',
    },
    {
      type: 'totalPremium',
      description: 'Total premium ',
      isHighlighted: true,
    },
  ],
  [dutchInusranceProductType.FIFTY_PLUS]: [
    {
      type: 'base_wa',
      description: 'Third Party Liability coverage',
      heading: 'Liability coverage (Third Party Liability)',
    },
    {
      type: 'base_casco',
      description: 'Own damage coverage',
      heading: 'Casco TPL/WA (Own Damage)',
      hideWhenZero: true,
    },
    {
      type: 'administration_costs',
      description: 'Administration costs',
    },
    {
      type: 'tax',
      isDividerHidden: true,
      description: 'Insurance tax',
    },
    {
      type: 'totalPremium',
      description: 'Total premium ',
      isHighlighted: true,
    },
  ],
  [dutchInusranceProductType.PRIVATE_HOME]: [
    {
      heading: 'Insured amount',
      type: 'insurance',
      description: 'Premium',
    },
    {
      heading: 'Extras',
      type: 'freeAccident',
      description: 'Accident coverage (see conditions)',
    },
    {
      isDividerHidden: true,
      type: 'freeLiability',
      heading: true,
      description: 'Liability coverage (see conditions)',
    },
    {
      type: 'administration_costs',
      description: 'Administration costs',
    },
    {
      isDividerHidden: true,
      type: 'tax',
      description: 'Insurance tax',
    },
    {
      type: 'totalPremium',
      description: 'Total premium ',
      isHighlighted: true,
    },
  ],
  [dutchInusranceProductType.PRIVATE_BASIC_HOME]: [
    {
      heading: 'Insured amount',
      type: 'insurance',
      description: 'Premium',
    },
    // {
    //   heading: 'Extras',
    //   type: 'freeAccident',
    //   description: 'Accident coverage (see conditions)',
    // },
    {
      heading: 'Extras',
      isDividerHidden: false,
      type: 'freeLiability',
      description: 'Liability coverage (see conditions)',
    },
    {
      type: 'administration_costs',
      description: 'Administration costs',
    },
    {
      isDividerHidden: true,
      type: 'tax',
      description: 'Insurance tax',
    },
    {
      type: 'totalPremium',
      description: 'Total premium ',
      isHighlighted: true,
    },
  ],
  [dutchInusranceProductType.LIABILITY]: [
    {
      heading: 'Insurance amount',
      type: 'insurance',
      description: 'Premium',
    },
    {
      type: 'administration_costs',
      description: 'Administration costs',
    },
    {
      isDividerHidden: true,
      type: 'tax',
      description: 'Insurance tax',
    },
    {
      type: 'totalPremium',
      description: 'Total premium ',
      isHighlighted: true,
    },
  ],
};
